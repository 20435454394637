import React from 'react';
import { GalleryCard, useRestaurantMenu } from '@fingermarkglobal/cringer.components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import OffersImage from '../../../assets/images/OffersImage_600x600.png';
import RewardsImage from '../../../assets/images/RewardsImage_ver2_600x600.png';

const OffersAndRewards = () => {
  const history = useHistory();
  const match = useRouteMatch('/categories');
  const { offersAndRewards } = useRestaurantMenu();

  const hasOffers = offersAndRewards?.offers?.length > 0;
  const hasRewards = offersAndRewards?.rewards?.length > 0;

  const noOffersAndRewards = !hasOffers && !hasRewards;

  if (!match) {
    return null;
  }

  if (noOffersAndRewards) {
    return null;
  }

  return (
    <div className="p-8 pt-12">
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4">
        {hasOffers ? (
          <GalleryCard
            image={OffersImage}
            title="Offers & Deals"
            onClick={() => history.push('/category/offers')}
            imageHeightClassName="h-73"
          />
        ) : null}
        {hasRewards ? (
          <GalleryCard
            image={RewardsImage}
            title="Rewards"
            onClick={() => history.push('/category/rewards')}
            imageHeightClassName="h-73"
          />
        ) : null}
      </div>
    </div>
  );
};

export { OffersAndRewards };
