import React, { useCallback } from 'react';

import { PrimaryHome } from '../../components/stateless/primary-home';
import { SecondaryHome } from '../../components/stateless/secondary-home';
import {
  Alert,
  Loading,
  useRestaurantHomeView,
  AdminButton,
  Modal,
  useModalPromise,
} from '@fingermarkglobal/cringer.components';
import { LoginOrSkipModal } from '../generic/loyalty-login/LoginOrSkipModal';

const CustomHomeView = () => {
  const {
    t,
    state,
    onAdminActivate,
    showSecondaryHome,
    accessibilityEnabled,
    languages,
    primaryAction,
    secondaryAction,
    onChangeLanguage,
    showSecondaryAction,
    card,
    cash,
    icons,
    isOnline,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
    loyaltyEnabled,
  } = useRestaurantHomeView();

  const { modal, showModal } = useModalPromise({ timeoutSeconds: 10 });

  const shouldSkipLoyaltyLogin = useCallback(async () => {
    let skipLoyaltyLogin = true;
    if (!loyaltyEnabled) return { skipLoyaltyLogin };
    try {
      const goToLoyalty = await showModal((resolve, _) => {
        return {
          onLoginClick: () => resolve(true),
          onSkipClick: () => resolve(false),
        };
      });
      skipLoyaltyLogin = !goToLoyalty;
    } catch (e) {
      if (e !== undefined) throw e;
      return { timedOut: true };
    }
    return { skipLoyaltyLogin };
  }, [showModal, loyaltyEnabled]);

  const primaryActionWithLoyaltyCheck = useCallback(async () => {
    const { skipLoyaltyLogin, timedOut } = await shouldSkipLoyaltyLogin();
    if (timedOut) return;
    primaryAction({ skipLoyaltyLogin });
  }, [primaryAction, shouldSkipLoyaltyLogin]);

  const secondaryActionWithLoyaltyCheck = useCallback(async () => {
    const { skipLoyaltyLogin, timedOut } = await shouldSkipLoyaltyLogin();
    if (timedOut) return;
    secondaryAction({ skipLoyaltyLogin });
  }, [secondaryAction, shouldSkipLoyaltyLogin]);

  const params = {
    accessibilityEnabled,
    languages,
    onChangeLanguage,
    primaryAction: primaryActionWithLoyaltyCheck,
    secondaryAction: showSecondaryAction ? secondaryActionWithLoyaltyCheck : undefined,
    t,
    card,
    cash,
    icons,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  };

  return (
    <div className="flex flex-col items-center justify-center h-full relative">
      <AdminButton onActivate={onAdminActivate} />
      {state?.matches('failure') && <Alert type="danger" message={state.context.message} />}
      {state?.matches('loading') && <Loading isFirstLoad={true} />}
      {state?.matches('success') &&
        (!isOnline ? (
          <Alert type="warning" message={t('homeConnectivityIssues')} className="text-white" />
        ) : showSecondaryHome ? (
          <SecondaryHome {...params} />
        ) : (
          <PrimaryHome {...params} />
        ))}
      <Modal open={modal.open}>
        <LoginOrSkipModal t={t} {...modal} />
      </Modal>
    </div>
  );
};

export { CustomHomeView };
