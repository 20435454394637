import React from 'react';
import { ModalHeader, ModalFooter } from '@fingermarkglobal/cringer.components';

export const LoginFailedModal = ({ t, onClose }) => (
  <div className="p-10">
    <ModalHeader title={t('loyaltyLoginFailedModalTitle')} />
    <p className="text-3xl p-0 modal-text modal-content">{t('loyaltyLoginFailedModalContent')}</p>
    <p className="text-3xl p-0 modal-text modal-content">
      {t('loyaltyLoginFailedModalSubContent')}
    </p>
    <ModalFooter
      action={onClose}
      actionText={t('loyaltyLoginFailedModalClose')}
      buttonsClassName={'w-full'}
    />
  </div>
);
