import React from 'react';

export const SVGCrown = props => (
  <svg
    width="40"
    height="25"
    viewBox="0 0 40 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.4949 2.92786L33.4386 5.04028L30.3823 7.1527C29.92 7.46732 29.3807 7.59573 28.8606 7.53152C28.3405 7.47373 27.8397 7.22975 27.4609 6.81882L24.7385 3.85244L22.0161 0.886062C21.4768 0.295354 20.7384 0 20 0C19.2616 0 18.5232 0.295354 17.9839 0.886062L15.2615 3.85244L12.5391 6.81882C12.1603 7.22975 11.6595 7.47373 11.1394 7.53152C10.6193 7.58931 10.08 7.46732 9.61767 7.1527L6.5614 5.04028L3.50514 2.92786C2.72181 2.38851 1.79722 2.42704 1.09736 2.84439C0.397501 3.26174 -0.0840556 4.04506 0.0122555 4.99533L0.898315 13.7917L1.78438 22.5881C1.84217 23.1596 2.11184 23.6604 2.50992 24.02C2.90801 24.3795 3.43451 24.5978 4.01237 24.5978H20H35.9876C36.5591 24.5978 37.092 24.3795 37.4901 24.02C37.8882 23.6604 38.1578 23.1596 38.2156 22.5881L39.1017 13.7917L39.9877 4.99533C40.0841 4.04506 39.6025 3.26174 38.9026 2.84439C38.1964 2.42704 37.2782 2.38851 36.4949 2.92786Z"
      fill="#FFAA00"
    />
  </svg>
);
