import React from 'react';
import classNames from 'classnames';
import { SVGCrown } from '../../../assets/icons/SVGCrown';

const RewardPoints = ({ pointsCost }) => {
  if (!pointsCost) {
    return null;
  }
  return (
    <span
      className={classNames(
        'flex items-center gap-4',
        'px-4 py-2 rounded-lg',
        'text-white  bg-primary',
      )}
    >
      <SVGCrown width={40} height={40} />
      <span className="text-2xl">{pointsCost}</span>
    </span>
  );
};

export { RewardPoints };
