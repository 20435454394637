import React from 'react';

import {
  BannerWarning,
  PrimaryButtonLarge,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultSecondaryBackground from '../../../assets/images/BKSecondaryBG.jpg';

const SecondaryHome = ({
  t,
  healthMsg = true,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultSecondaryBackground,
          color: '#F5EBDC',
        },
      }}
    >
      <HomeCustomisation.Background />

      <div data-test="secondary-home" className="z-10 flex flex-col items-center w-full h-full">
        <div className="flex flex-col w-full h-full text-center font-secondary">
          <p className="text-9xl">{t('homeTitle')}</p>

          <HomeCustomisation.HeroImage />

          <div
            data-test="home-upper"
            className="flex flex-wrap items-stretch justify-around mt-auto"
          >
            {!!secondaryAction && (
              <PrimaryButtonLarge
                className="w-2/5 mx-4 mb-8"
                data-test="button-secondary"
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            <PrimaryButtonLarge
              className="w-2/5 mx-4 mb-8"
              data-test="button-primary"
              onClick={primaryAction}
            >
              {!!secondaryAction || !!tertiaryAction
                ? t('homePrimaryAction')
                : t('homePrimaryAlternativeAction')}
            </PrimaryButtonLarge>

            {!!tertiaryAction && (
              <PrimaryButtonLarge className="w-2/5 mx-4 mb-8" onClick={tertiaryAction}>
                {t('homeTertiaryAction')}
              </PrimaryButtonLarge>
            )}

            {showTermsAndConditionsButton && healthMsg && (
              <div
                className="flex flex-col-reverse items-center justify-start w-full h-full mt-12"
                data-test="home-lower"
              >
                {showTermsAndConditionsButton && (
                  <BannerWarning
                    t={t}
                    width="109"
                    className="w-4/5 mt-8"
                    data-test="banner-terms"
                    title={t('homeTermsAndConditionsBannerWarningTitle')}
                    description={t('homeTermsAndConditionsBannerWarningDescription')}
                    additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
                    additionalDescription={t(
                      'homeTermsAndConditionsBannerWarningAdditionalDescription',
                    )}
                  />
                )}
                {healthMsg && (
                  <BannerWarning
                    t={t}
                    width="109"
                    className="w-4/5 mt-8"
                    data-test="banner-health"
                    image={WashHandsImage}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <p data-test="home-footer-title" className="mb-24 text-5xl text-white">
          {t('homeFooterTitle')}
        </p>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
