import React from 'react';
import { ModalHeader, ModalFooter } from '@fingermarkglobal/cringer.components';
import { SVGCrown } from '../../../assets/icons/SVGCrown';
import { SVGRoyalPerksGoldWide } from '../../../assets/icons/SVGRoyalPerksGoldWide';

export const LoyaltyWelcomeModal = ({ onConfirm, loyaltyUser, t }) => {
  const { name, points } = loyaltyUser || {};

  return (
    <div className="p-10 flex flex-col justify-center">
      <div className="py-8">
        <SVGRoyalPerksGoldWide width={'auto'} height={56} />
      </div>
      <ModalHeader title={t('loyaltyWelcomeModalTitle', { name })} />
      <div className="flex flex-initial gap-1 items-center justify-center">
        <p className="text-3xl p-0 modal-text modal-content">
          {t('loyaltyWelcomeModalPoints', { points })}
        </p>
        <SVGCrown width={24} height={24} />
      </div>
      <ModalFooter
        action={onConfirm}
        actionText={t('loyaltyWelcomeModalConfirm')}
        buttonsClassName={'w-full'}
      />
    </div>
  );
};
