import React from 'react';
import { SVGCrown } from '../../../assets/icons/SVGCrown';

export const MemberTitleComponent = ({ t, loyaltyUser }) => {
  const { points } = loyaltyUser;
  return (
    <div className="loyalty-banner-title-wrapper">
      <SVGCrown width={40} height={40} />
      <p className="loyalty-banner-title-wrapper-points">{points}</p>
      <p className="loyalty-banner-title-wrapper-text">{t('loyaltyBannerPointsTitle')}</p>
    </div>
  );
};
