import React from 'react';
import {
  Alert,
  Loading,
  useRestaurantCategoriesView,
  Modal,
  MiniCartModal,
  ProductCustomisationModal,
  Gallery,
} from '@fingermarkglobal/cringer.components';

const CustomCategoriesView = () => {
  const {
    state,
    loading,
    formattedCategories,
    showMiniCartModal,
    onCloseMiniCartModal,
    onConfirmMiniCartModal,
    originPath,
    showPriceWarning,
    openProductCustomisationModal,
    customisationProductSelected,
    onCloseProductCustomisationModal,
    onConfirmProductCustomisationModal,
    modalAccessibilityClass,
    formatPrice,
    isCustomisationHeaderEnabled,
    isCategoryTotalizerEnabled,
    isAdditionalMessageEnabled,
    showQuantityControllerLabel,
    galleryMaxColumns,
    showAddonAvailability,
    disableModifyUpsellOnCart,
    hideDisabledButtons,
    hidePriceWhenQuantityLessOrEqualDefault,
    hidePriceWhenZero,
  } = useRestaurantCategoriesView();

  return (
    <>
      <Modal open={showMiniCartModal} className={modalAccessibilityClass} formatPrice={formatPrice}>
        <MiniCartModal
          onCloseCallback={onCloseMiniCartModal}
          onConfirmCallback={onConfirmMiniCartModal}
          origin={originPath}
          showPriceWarning={showPriceWarning}
          disableModifyUpsellOnCart={disableModifyUpsellOnCart}
          hideDisabledButtons={hideDisabledButtons}
        />
      </Modal>
      <Modal
        open={openProductCustomisationModal}
        className={modalAccessibilityClass}
        formatPrice={formatPrice}
      >
        <ProductCustomisationModal
          product={customisationProductSelected}
          onCloseCallback={onCloseProductCustomisationModal}
          onConfirmCallback={onConfirmProductCustomisationModal}
          showPriceWarning={showPriceWarning}
          isCustomisationHeaderEnabled={isCustomisationHeaderEnabled}
          isCategoryTotalizerEnabled={isCategoryTotalizerEnabled}
          isAdditionalMessageEnabled={isAdditionalMessageEnabled}
          showQuantityControllerLabel={showQuantityControllerLabel}
          showAddonAvailability={showAddonAvailability}
          hidePriceWhenQuantityLessOrEqualDefault={hidePriceWhenQuantityLessOrEqualDefault}
          hidePriceWhenZero={hidePriceWhenZero}
        />
      </Modal>
      {loading && (
        <div className="flex flex-col items-center justify-center h-full p-8">
          <Loading />
        </div>
      )}
      {state?.matches('failure') && (
        <div className="flex flex-col items-center justify-center h-full p-8">
          <Alert type="danger" message={state.context.message} />
        </div>
      )}
      {state?.matches('success') && (
        <>
          <Gallery
            items={formattedCategories}
            formatPrice={formatPrice}
            maxColumns={galleryMaxColumns}
            className={`gallery-card-categories`}
          />
        </>
      )}
    </>
  );
};

export { CustomCategoriesView };
