import React from 'react';
import { SVGRoyalPerksGoldWide } from '../../../assets/icons/SVGRoyalPerksGoldWide';

export const GuestTitleComponent = ({ t }) => (
  <div className="flex h-full gap-4 items-center">
    <div>
      <SVGRoyalPerksGoldWide height={'100%'} width={'auto'} />
    </div>
    <p data-test="loyalty-banner-title" className="loyalty-banner-text-wrapper-title">
      {t('loyaltyBannerTitle')}
    </p>
  </div>
);
