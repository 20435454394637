import React from 'react';

import {
  Alert,
  Gallery,
  Loading,
  Banner,
  Modal,
  ProductCustomisationModal,
  useRestaurantCategoryView,
  UpsellModal,
  ProductAnimationSpawn,
  MiniCartModal,
  AddonRemovedModal,
} from '@fingermarkglobal/cringer.components';
import { RewardGalleryCard } from '../../components/stateless/offers-and-rewards';
import { SVGCrown } from '../../assets/icons/SVGCrown';

const RestaurantCategoryRewardsView = () => {
  const {
    ready,
    loading,
    menuState,
    formattedProducts,
    t,
    openGroupedProductModal,
    onCloseGroupedProductModal,
    groupedProductSelected,
    openComboProductModal,
    onCloseComboProductModal,
    comboProductSelected,
    customisationProductSelected,
    openProductCustomisationModal,
    onCloseProductCustomisationModal,
    onConfirmProductCustomisationModal,
    openUpsellModal,
    formattedUpsells,
    onCloseUpsellModal,
    modalAccessibilityClass,
    displayDescription,
    showPriceWarning,
    showMiniCartModal,
    onCloseMiniCartModal,
    onConfirmMiniCartModal,
    originPath,
    formatPrice,
    isCustomisationHeaderEnabled,
    isCustomiseCategoryTitleEnabled,
    isCategoryTotalizerEnabled,
    isAdditionalMessageEnabled,
    showQuantityControllerLabel,
    galleryMaxColumns,
    showBanner,
    bannerTitle,
    bannerSubTitle,
    bannerImage,
    placeholder,
    showAddonAvailability,
    showEnergyInformationSeparated,
    showModifierEnergyInformation,
    openCondAddonModal,
    onCloseAddonRemovedModal,
    titleSeparator,
    disableModifyUpsellOnCart,
    hideDisabledButtons,
    hidePriceWhenQuantityLessOrEqualDefault,
    hidePriceWhenZero,
    products,
    offersAndRewards,
  } = useRestaurantCategoryView();

  // openProductCustomisationModal could be directAdd, customise or null
  const addToCartDirectly = openProductCustomisationModal === 'directAdd';
  const showCustomisationModal = addToCartDirectly ? 'hidden' : '';

  return (
    <>
      <ProductAnimationSpawn />
      <Modal open={showMiniCartModal} formatPrice={formatPrice}>
        <MiniCartModal
          onCloseCallback={onCloseMiniCartModal}
          onConfirmCallback={onConfirmMiniCartModal}
          origin={originPath}
          showPriceWarning={showPriceWarning}
          disableModifyUpsellOnCart={disableModifyUpsellOnCart}
          hideDisabledButtons={hideDisabledButtons}
        />
      </Modal>
      <Modal
        data-test="grouped-option-modal"
        className={`${modalAccessibilityClass} grouped-option-modal`}
        open={openGroupedProductModal}
        title={groupedProductSelected?.title}
        closeModal={onCloseGroupedProductModal}
        closeText={t('categoryGroupedModalSecondaryAction')}
        formatPrice={formatPrice}
      >
        <Gallery
          items={groupedProductSelected?.grouped}
          dynamicColumns={true}
          formatPrice={formatPrice}
          showAddonAvailability={showAddonAvailability}
          showEnergyInformationSeparated={showEnergyInformationSeparated}
          t={t}
        />
      </Modal>
      <Modal
        data-test="combo-option-modal"
        className={`combo-option-modal ${modalAccessibilityClass}`}
        open={openComboProductModal}
        title={t('categoryComboModalTitle')}
        closeModal={onCloseComboProductModal}
        closeText={t('categoryComboModalSecondaryAction')}
        formatPrice={formatPrice}
      >
        <Gallery
          items={comboProductSelected?.options}
          dynamicColumns={true}
          formatPrice={formatPrice}
          showAddonAvailability={showAddonAvailability}
          showEnergyInformationSeparated={showEnergyInformationSeparated}
          t={t}
        />
      </Modal>
      <Modal
        open={!!openProductCustomisationModal}
        className={`${modalAccessibilityClass} ${showCustomisationModal}`}
        formatPrice={formatPrice}
      >
        <ProductCustomisationModal
          addToCartDirectly={addToCartDirectly}
          product={customisationProductSelected}
          onCloseCallback={onCloseProductCustomisationModal}
          onConfirmCallback={onConfirmProductCustomisationModal}
          showPriceWarning={showPriceWarning}
          isCustomisationHeaderEnabled={isCustomisationHeaderEnabled}
          isCustomiseCategoryTitleEnabled={isCustomiseCategoryTitleEnabled}
          isCategoryTotalizerEnabled={isCategoryTotalizerEnabled}
          isAdditionalMessageEnabled={isAdditionalMessageEnabled}
          showQuantityControllerLabel={showQuantityControllerLabel}
          showAddonAvailability={showAddonAvailability}
          showModifierEnergyInformation={showModifierEnergyInformation}
          titleSeparator={titleSeparator}
          hidePriceWhenQuantityLessOrEqualDefault={hidePriceWhenQuantityLessOrEqualDefault}
          hidePriceWhenZero={hidePriceWhenZero}
        />
      </Modal>
      <UpsellModal
        open={openUpsellModal}
        closeModal={onCloseUpsellModal}
        t={t}
        products={formattedUpsells}
        className={modalAccessibilityClass}
        formatPrice={formatPrice}
        showAddonAvailability={showAddonAvailability}
        showEnergyInformationSeparated={showEnergyInformationSeparated}
      />

      <Modal open={openCondAddonModal && !showMiniCartModal}>
        <AddonRemovedModal onCloseCallback={onCloseAddonRemovedModal} />
      </Modal>

      {(loading || !ready) && (
        <div className="flex flex-col items-center justify-center h-full p-8">
          <Loading />
        </div>
      )}
      {menuState?.matches('failure') && (
        <div className="flex flex-col items-center justify-center h-full p-8">
          <Alert type="danger" message={menuState.context.message} />
        </div>
      )}

      {showBanner && (
        <Banner
          title={bannerTitle}
          subTitle={bannerSubTitle}
          image={bannerImage}
          placeholder={placeholder}
        />
      )}

      {menuState?.matches('success') &&
        offersAndRewards.rewardsCategoriesMap.map(rewardCategory => {
          const categoryTitle = rewardCategory.name.en;
          const rewardProducts = products.filter(product =>
            rewardCategory.rewardIds.includes(product.providerData?.PromoId?.toString()),
          );
          if (rewardProducts.length === 0) {
            return null;
          }
          const filteredFormattedProducts = formattedProducts.filter(formattedProduct =>
            rewardProducts.some(product => product.id === formattedProduct.productId),
          );
          return (
            <div key={categoryTitle}>
              <div className="p-2 sm:p-4 md:p-8 bg-black/0.04 text-5xl">
                <h3 className="flex gap-4">
                  <SVGCrown width={40} height={40} />
                  <span>{categoryTitle} Crowns</span>
                </h3>
              </div>
              <Gallery
                descriptive={displayDescription}
                items={filteredFormattedProducts}
                showEnergyInformationSeparated={showEnergyInformationSeparated}
                galleryCardClassName={'gallery-card-category'}
                formatPrice={formatPrice}
                maxColumns={galleryMaxColumns}
                showAddonAvailability={showAddonAvailability}
                t={t}
                Card={RewardGalleryCard}
              />
            </div>
          );
        })}
    </>
  );
};

export { RestaurantCategoryRewardsView };
