import React from 'react';

export const SVGRoyalPerksGoldWide = props => (
  <svg
    width="800"
    height="145"
    viewBox="0 0 800 145"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_182_27585)">
      <path
        d="M44.94 31.2878C44.94 25.5679 41.38 23.5378 38.21 23.5378C36.05 23.5378 35.29 24.2978 35.29 26.4578V36.2379C35.29 38.3979 36.3 39.1579 38.21 39.1579C41.38 39.1579 44.94 36.9978 44.94 31.2778M50.14 104.028C46.71 93.0179 44.68 68.6279 44.68 68.6279C43.92 62.6579 42.9 59.0979 39.47 59.0979C36.8 59.0979 35.53 61.2579 35.53 64.3078C35.53 70.9178 36.55 74.4778 37.69 76.8878C38.83 79.2979 40.1 81.2078 40.1 84.2579C40.1 88.4479 37.94 91.2478 34.13 91.2478H7.20998C2.12998 91.2478 0.0999756 88.7078 0.0999756 84.6378C0.0999756 82.7278 0.609976 80.6979 1.23998 78.4079C3.01998 72.5679 3.26998 63.6679 3.26998 46.7779C3.26998 29.8879 2.88998 20.6079 1.23998 15.1479C0.599976 12.8579 0.0999756 10.6979 0.0999756 8.92785C0.0999756 4.86785 2.12998 2.32785 7.20998 2.32785H44.05C62.34 2.32785 76.45 10.3379 76.45 28.4979C76.45 38.6579 71.5 45.6479 60.7 48.5679C60.06 48.8179 59.56 49.3279 59.56 49.8379C59.56 50.2179 59.94 50.7279 60.7 50.9779C70.36 54.5379 74.8 58.0879 79.12 66.2279C80.84 69.3979 85.2 85.2979 86.08 114.738C86.31 122.508 92.7 129.478 96.77 129.478C99.06 129.478 100.71 128.338 100.71 126.308C100.71 122.498 93.85 121.348 93.85 111.568C93.85 102.798 100.58 95.9379 110.75 95.9379C119.39 95.9379 128.03 102.548 128.03 115.498C128.03 134.048 113.04 144.078 94.37 144.078C69.34 144.078 56.13 123.188 50.16 104.038"
        fill="#FF8732"
      />
      <path
        d="M129.05 46.7879C129.05 26.8379 127.78 24.1779 124.48 24.1779C121.18 24.1779 119.91 26.8479 119.91 46.7879C119.91 66.7279 121.18 69.3979 124.48 69.3979C127.78 69.3979 129.05 66.5979 129.05 46.7879ZM84.33 46.7879C84.33 17.3179 101.73 0.547852 124.47 0.547852C147.21 0.547852 164.61 17.3179 164.61 46.7879C164.61 76.2579 147.33 93.0279 124.47 93.0279C101.61 93.0279 84.33 76.2579 84.33 46.7879Z"
        fill="#FF8732"
      />
      <path
        d="M181.89 84.8979C181.89 83.1179 182.4 81.7178 182.91 80.3279C184.31 76.7679 185.32 72.7079 185.32 66.4779V64.6979C185.32 60.8879 184.81 58.4779 182.78 54.6579C178.08 45.6379 173 36.6179 166.14 24.7979C162.2 17.9379 159.15 16.1579 159.15 10.1879C159.15 6.75785 161.57 2.30785 167.92 2.30785H199.43C204.39 2.30785 206.92 5.98785 206.92 9.67785C206.92 15.6479 203.87 16.6679 203.87 20.8579C203.87 23.3979 204.5 24.9279 205.65 27.3379C206.54 29.2379 207.3 30.5079 208.57 30.5079C209.71 30.5079 210.22 29.6179 211.49 27.3379C212.76 24.9279 213.4 23.0179 213.4 20.8579C213.4 16.6679 211.24 13.9979 211.24 9.92785C211.24 4.97785 214.67 2.30785 218.99 2.30785H237.15C241.34 2.30785 244.39 5.22785 244.39 9.67785C244.39 15.3979 240.96 17.0479 236.39 24.6679C233.09 30.1279 229.66 36.7379 226.48 42.5779C222.03 50.8379 218.6 56.6779 218.6 64.6879V66.4678C218.6 72.6879 219.74 76.7579 221.01 80.3178C221.52 81.7178 222.03 83.1078 222.03 84.8878C222.03 88.5779 219.62 91.2379 215.68 91.2379H188.24C184.3 91.2379 181.89 88.5678 181.89 84.8878"
        fill="#FF8732"
      />
      <path
        d="M269.55 49.8379C270.57 49.8379 271.2 49.4579 271.08 48.4379C270.44 44.4979 269.17 39.4179 268.79 38.0179C268.53 36.9979 268.03 36.4879 267.27 36.4879C266.38 36.4879 266 36.9979 265.74 38.0179C265.36 39.5379 264.34 44.4979 263.45 48.8179C263.32 49.4479 263.83 49.8379 264.47 49.8379H269.55ZM229.28 83.6279C229.28 81.9779 229.66 80.1979 230.42 78.4179C237.28 61.5279 246.43 32.3078 252.27 13.5079C254.94 4.86785 259.26 2.32785 265.35 2.32785H276.02C282.37 2.32785 287.2 4.99785 289.87 13.1279C296.48 33.4579 308.55 67.8779 313.37 78.8079C314.01 80.3279 314.39 81.8578 314.39 83.2579C314.39 87.7078 311.34 91.2579 306.9 91.2579H277.04C272.21 91.2579 270.18 88.0779 270.18 84.6479C270.18 83.3779 270.56 81.9779 271.07 80.8379C271.96 78.9379 272.47 77.1579 272.47 75.7579C272.47 73.8578 271.58 72.4578 269.42 72.3279C267.26 72.1979 266.88 72.1979 264.72 72.3279C262.56 72.4479 261.42 73.9779 261.42 76.2679C261.42 77.6679 261.8 79.4479 262.69 81.3479C263.07 82.3679 263.32 83.5079 263.32 84.6479C263.32 87.8279 261.54 91.2579 257.22 91.2579H237.15C231.81 91.2579 229.27 87.8279 229.27 83.6378"
        fill="#FF8732"
      />
      <path
        d="M317.31 84.6479C317.31 82.7378 317.82 80.7079 318.45 78.4179C320.23 72.5779 320.48 63.6879 320.48 46.7879C320.48 29.8879 320.1 20.6179 318.45 15.1579C317.82 12.8679 317.31 10.7079 317.31 8.93785C317.31 4.87785 319.34 2.32785 324.42 2.32785H349.32C354.91 2.32785 357.83 5.11785 357.83 9.31785C357.83 12.3679 356.81 14.2779 355.41 16.6878C354.01 19.0979 352.74 22.5278 352.49 27.1078C352.23 32.3178 352.11 37.5279 352.11 42.7279C352.11 48.5679 352.24 54.4179 352.49 60.2579C352.74 64.7079 354.78 67.1179 359.1 67.1179C367.74 67.1179 366.47 55.6879 376.25 55.6879C383.74 55.6879 386.03 60.6479 385.01 66.2279C384.12 70.5479 383.61 74.9979 383.36 80.1979C382.98 87.5679 379.55 91.2478 372.31 91.2478H325.05C319.97 91.2478 317.3 88.7078 317.3 84.6378"
        fill="#FF8732"
      />
      <path
        d="M452.61 31.2878C452.61 25.5678 449.05 23.5378 445.88 23.5378C443.72 23.5378 442.96 24.2978 442.96 26.4578V36.2378C442.96 38.3978 443.98 39.1578 445.88 39.1578C449.05 39.1578 452.61 36.9978 452.61 31.2778M407.77 84.6378C407.77 82.7278 408.27 80.6979 408.91 78.4079C410.69 72.5678 410.94 63.6678 410.94 46.7778C410.94 29.8878 410.56 20.6078 408.91 15.1478C408.27 12.8578 407.77 10.6978 407.77 8.92785C407.77 4.86785 409.8 2.31785 414.88 2.31785H448.55C466.84 2.31785 479.93 11.0778 479.93 31.0278C479.93 50.9778 466.84 58.3378 447.91 58.3378C444.86 58.3378 442.96 59.3578 442.96 63.7978C442.96 70.2778 443.98 73.7078 445.5 76.8878C446.64 79.2979 447.53 81.3279 447.53 84.2579C447.53 88.4479 445.37 91.2478 439.65 91.2478H414.88C409.8 91.2478 407.77 88.7078 407.77 84.6378Z"
        fill="#FF8732"
      />
      <path
        d="M483.09 84.6479C483.09 82.7378 483.6 80.7079 484.23 78.4179C486.01 72.5779 486.26 63.6879 486.26 46.7879C486.26 29.8879 485.88 20.6179 484.23 15.1579C483.6 12.8679 483.09 10.7079 483.09 8.93785C483.09 4.87785 485.12 2.32785 490.2 2.32785H533.65C542.04 2.32785 545.59 5.37785 545.59 11.0979C545.59 13.7679 546.1 17.7078 546.86 20.3778C548.51 26.2178 544.96 31.4278 538.6 31.4278C530.98 31.4278 529.33 25.0779 523.23 25.0779C520.69 25.0779 518.4 26.7278 518.4 30.4079C518.4 33.8379 520.81 35.6179 523.99 35.6179C528.18 35.6179 528.06 33.9678 532.37 33.9678C537.83 33.9678 540.75 38.4179 540.75 45.6579C540.75 52.8979 537.83 58.3679 532.37 58.3679C528.05 58.3679 528.18 55.5779 523.99 55.5779C521.07 55.5779 518.4 57.3579 518.4 60.6579C518.4 64.7179 521.19 66.6279 525.26 66.6279C535.43 66.6279 534.15 58.6179 542.92 58.6179C549.65 58.6179 552.32 63.5779 551.05 69.7979C550.42 73.0979 550.03 76.9178 550.03 80.2178C550.03 87.5879 545.2 91.2679 536.44 91.2679H490.2C485.12 91.2679 483.09 88.7279 483.09 84.6579"
        fill="#FF8732"
      />
      <path
        d="M599.46 31.2878C599.46 25.5678 595.9 23.5378 592.73 23.5378C590.57 23.5378 589.81 24.2978 589.81 26.4578V36.2378C589.81 38.3978 590.83 39.1578 592.73 39.1578C595.91 39.1578 599.46 36.9978 599.46 31.2778M554.62 84.6378C554.62 82.7278 555.12 80.6979 555.76 78.4079C557.54 72.5678 557.79 63.6678 557.79 46.7778C557.79 29.8878 557.41 20.6078 555.76 15.1478C555.12 12.8578 554.62 10.6978 554.62 8.92785C554.62 4.86785 556.65 2.31785 561.73 2.31785H598.32C616.62 2.31785 630.72 10.3278 630.72 28.4878C630.72 39.5378 625.38 45.2579 619.79 47.9278C618.9 48.3078 618.39 48.8178 618.39 49.3178C618.39 49.9578 618.77 50.4578 619.79 50.9678C627.41 54.9078 630.33 62.2779 633 71.5479C634.65 77.3879 636.3 79.0378 636.3 83.1078C636.3 87.8078 633.76 91.2379 626.77 91.2379H607.84C601.36 91.2379 598.19 88.3178 598.19 82.4678C598.19 78.3979 598.44 74.0878 598.44 65.5678C598.44 61.1178 596.66 59.0878 593.99 59.0878C591.07 59.0878 589.8 61.2478 589.8 64.2978C589.8 68.3679 590.82 73.1879 592.09 76.6179C592.98 79.0378 594.38 81.6979 594.38 84.2379C594.38 88.4279 592.22 91.2279 586.5 91.2279H561.73C556.65 91.2279 554.62 88.6879 554.62 84.6179"
        fill="#FF8732"
      />
      <path
        d="M639.09 84.6479C639.09 82.7378 639.6 80.7079 640.23 78.4179C642.01 72.5779 642.26 63.6879 642.26 46.7879C642.26 29.8879 641.88 20.6179 640.23 15.1579C639.6 12.8679 639.09 10.7079 639.09 8.93785C639.09 4.87785 641.12 2.32785 646.2 2.32785H671.1C676.06 2.32785 678.47 5.24785 678.47 9.05785C678.47 11.0879 677.96 12.6179 677.07 15.1579C675.54 19.7379 675.16 24.1779 675.16 34.8479C675.16 36.1179 675.92 36.8779 676.81 36.8779C677.44 36.8779 678.21 36.4978 678.97 35.7379C683.54 30.7878 685.07 28.6178 688.12 23.9278C689.01 22.6578 689.52 20.8778 689.52 19.0978C689.52 14.3978 686.47 13.5079 686.47 8.93785C686.47 5.50785 688.63 2.32785 693.71 2.32785H718.74C723.06 2.32785 725.09 5.50785 725.09 9.05785C725.09 11.2179 724.33 13.6378 720.64 17.1878C715.56 22.0178 710.35 28.3678 705.14 34.7178C704.38 35.6078 703.99 36.7478 703.99 37.8979C703.99 38.5378 704.12 39.4279 704.5 40.1879C709.96 52.6379 722.16 74.3578 726.73 79.8279C728 81.4779 728.76 83.5079 728.76 85.4179C728.76 88.4679 726.47 91.2679 721.64 91.2679H690.65C686.58 91.2679 684.43 87.0779 684.17 81.7379C683.91 75.1279 682.01 67.2579 679.34 60.2679C678.83 58.9979 678.07 58.4879 677.31 58.4879C676.17 58.4879 675.28 59.3778 675.28 61.0279C675.28 64.9678 675.66 70.8078 677.06 75.8878C677.82 78.8078 678.46 81.3479 678.46 84.5279C678.46 88.3379 676.05 91.2579 671.22 91.2579H646.19C641.11 91.2579 639.08 88.7178 639.08 84.6479"
        fill="#FF8732"
      />
      <path
        d="M740.72 90.2379C728.14 85.9179 733.48 77.0279 731.32 65.8479C731.19 65.2079 731.19 64.7079 731.19 64.1979C731.19 59.3679 735.26 55.9379 739.7 55.9379C742.24 55.9379 745.04 57.0779 747.2 60.0079C751.52 65.8479 756.35 68.1378 760.92 68.1378C763.46 68.1378 765.11 67.1179 765.11 64.9579C765.11 62.5379 763.71 61.1479 754.06 57.7178C743.64 54.0279 731.83 46.7878 731.83 28.1179C731.83 9.44785 746.69 0.547852 765.62 0.547852C779.72 0.547852 790.77 1.81785 791.92 9.94785C792.56 14.6479 793.06 17.4479 794.71 23.2879C795.09 24.5579 795.22 25.5779 795.22 26.7179C795.22 31.7979 791.41 34.5979 786.96 34.5979C784.04 34.5979 780.86 33.4579 778.32 30.7879C772.98 25.3279 770.44 24.1879 767.77 24.1879C765.36 24.1879 763.96 25.3279 763.96 26.9779C763.96 29.1379 765.61 30.5279 773.87 33.1979C791.28 38.9179 799.03 46.4079 799.03 63.0479C799.03 80.7079 787.09 93.0279 762.7 93.0279C752.66 93.0279 747.07 92.3979 740.72 90.2279"
        fill="#FF8732"
      />
      <path
        d="M453.87 109.898L449.05 113.228L444.23 116.558C443.5 117.058 442.65 117.248 441.83 117.158C441.01 117.068 440.22 116.688 439.63 116.038L435.34 111.358L431.05 106.678C430.2 105.748 429.03 105.278 427.87 105.278C426.71 105.278 425.55 105.748 424.69 106.678L420.4 111.358L416.11 116.038C415.51 116.688 414.73 117.068 413.91 117.158C413.09 117.248 412.24 117.058 411.51 116.558L406.69 113.228L401.87 109.898C400.63 109.048 399.18 109.108 398.08 109.768C396.97 110.418 396.22 111.658 396.37 113.158L397.76 127.018L399.15 140.878C399.24 141.778 399.66 142.568 400.29 143.138C400.92 143.708 401.75 144.048 402.65 144.048H453.05C453.95 144.048 454.79 143.708 455.41 143.138C456.04 142.568 456.46 141.778 456.55 140.878L457.94 127.018L459.33 113.158C459.48 111.668 458.72 110.428 457.62 109.768C456.51 109.118 455.06 109.048 453.83 109.898"
        fill="#FF8732"
      />
      <path
        d="M151.46 141.788C151.46 141.348 151.58 140.908 151.86 140.468C152.54 139.348 152.94 138.068 152.94 136.108V123.708C152.94 121.748 152.54 120.468 151.86 119.348C151.58 118.908 151.46 118.468 151.46 118.028C151.46 116.868 152.34 115.908 153.86 115.908H168.18C170.18 115.908 171.34 116.948 171.54 118.908L171.78 121.228C171.94 122.748 171.18 123.828 169.62 123.828C168.42 123.828 167.9 123.268 167.26 122.148C166.7 121.148 165.5 120.708 164.14 120.708H160.78C160.06 120.708 159.66 121.108 159.66 121.828V125.908C159.66 126.628 160.06 127.028 160.78 127.028H162.94C163.82 127.028 164.5 126.548 164.74 125.948C165.02 125.228 165.62 124.748 166.5 124.748C167.46 124.748 168.1 125.228 168.26 126.228C168.58 128.268 168.58 130.308 168.26 132.348C168.1 133.348 167.46 133.828 166.5 133.828C165.62 133.828 165.06 133.348 164.74 132.628C164.46 131.988 163.82 131.508 162.94 131.508H160.78C160.06 131.508 159.66 131.908 159.66 132.628V137.988C159.66 138.708 160.06 139.108 160.78 139.108H164.7C166.26 139.108 167.5 138.548 168.1 137.108L168.46 136.268C168.9 135.268 169.54 134.668 170.7 134.668C172.02 134.668 172.82 135.588 172.74 137.028L172.54 140.788C172.42 142.868 171.34 143.908 169.3 143.908H153.86C152.34 143.908 151.46 142.948 151.46 141.788Z"
        fill="#502314"
      />
      <path
        d="M174.5 138.388C174.5 133.708 178.62 131.228 184.94 132.308C185.42 132.388 185.66 132.148 185.66 131.588V129.908C185.66 127.588 184.82 127.028 183.46 127.028C182.46 127.028 181.66 127.908 181.26 129.108C180.74 130.708 179.9 131.508 178.14 131.508C176.18 131.508 175.02 130.108 175.02 128.228C175.02 125.268 178.5 122.948 183.38 122.948C189.38 122.948 192.06 125.788 192.06 130.308V135.668C192.06 137.828 192.38 138.988 193.38 140.468C194.1 141.508 193.62 142.428 192.54 142.868L190.22 143.828C189.42 144.148 188.82 144.388 188.22 144.388C187.62 144.388 187.14 143.708 186.7 142.468C186.5 141.868 186.1 141.828 185.7 142.308C184.66 143.548 183.14 144.468 180.98 144.468C176.98 144.468 174.5 141.868 174.5 138.388ZM185.66 136.868C185.66 136.068 184.66 135.468 183.58 135.468C181.86 135.468 180.9 136.388 180.9 137.788C180.9 138.828 181.58 139.828 182.94 139.828C184.54 139.828 185.66 138.468 185.66 136.868Z"
        fill="#502314"
      />
      <path
        d="M195.3 141.668C195.62 140.548 195.82 139.628 195.82 137.908V128.428C195.82 127.348 195.54 126.508 195.3 125.948C194.9 124.988 195.46 123.828 196.54 123.628L199.66 123.028C201.46 122.668 202.22 123.388 202.22 124.748V125.268C202.22 125.748 202.54 126.068 203.02 125.468C204.34 123.828 205.42 122.948 207.22 122.948C211.78 122.948 211.46 131.228 207.38 131.228C205.18 131.228 205.06 128.708 203.86 128.708C202.82 128.708 202.22 130.188 202.22 132.348V137.908C202.22 139.628 202.42 140.548 202.74 141.668C203.14 143.068 202.5 143.908 201.06 143.908H196.98C195.54 143.908 194.9 143.068 195.3 141.668Z"
        fill="#502314"
      />
      <path
        d="M212.18 141.668C212.5 140.548 212.7 139.628 212.7 137.908V128.428C212.7 127.348 212.42 126.508 212.18 125.948C211.78 124.988 212.34 123.828 213.42 123.628L216.54 123.028C218.34 122.668 219.1 123.388 219.1 124.748V125.268C219.1 125.748 219.46 126.068 219.9 125.468C220.98 123.988 223.18 122.948 225.38 122.948C229.38 122.948 231.74 125.748 231.74 130.508V137.908C231.74 139.628 231.94 140.548 232.26 141.668C232.66 143.068 232.02 143.908 230.58 143.908H226.5C225.06 143.908 224.42 143.068 224.82 141.668C225.14 140.548 225.34 139.628 225.34 137.908V131.508C225.34 129.348 224.1 127.868 222.22 127.868C220.34 127.868 219.1 129.348 219.1 131.508V137.908C219.1 139.628 219.3 140.548 219.62 141.668C220.02 143.068 219.38 143.908 217.94 143.908H213.86C212.42 143.908 211.78 143.068 212.18 141.668Z"
        fill="#502314"
      />
      <path
        d="M241.38 129.908C241.38 121.748 246.82 115.348 255.82 115.348C257.94 115.348 259.7 115.748 260.94 116.228C262.46 116.828 263.18 117.908 263.34 119.388L263.54 121.468C263.78 124.068 260.54 125.188 258.94 122.828C257.94 121.348 256.42 120.548 254.78 120.548C250.94 120.548 248.38 123.828 248.38 128.428C248.38 133.508 251.66 137.908 256.42 137.908C258.38 137.908 259.78 137.468 261.06 136.468C262.06 135.708 263.06 135.668 263.78 136.348C264.66 137.148 264.7 138.108 264.06 139.188C262.22 142.308 259.54 144.468 254.9 144.468C246.58 144.468 241.38 138.388 241.38 129.908Z"
        fill="#502314"
      />
      <path
        d="M266.26 141.668C266.58 140.548 266.78 139.628 266.78 137.908V128.428C266.78 127.348 266.5 126.508 266.26 125.948C265.86 124.988 266.42 123.828 267.5 123.628L270.62 123.028C272.42 122.668 273.18 123.388 273.18 124.748V125.268C273.18 125.748 273.5 126.068 273.98 125.468C275.3 123.828 276.38 122.948 278.18 122.948C282.74 122.948 282.42 131.228 278.34 131.228C276.14 131.228 276.02 128.708 274.82 128.708C273.78 128.708 273.18 130.188 273.18 132.348V137.908C273.18 139.628 273.38 140.548 273.7 141.668C274.1 143.068 273.46 143.908 272.02 143.908H267.94C266.5 143.908 265.86 143.068 266.26 141.668Z"
        fill="#502314"
      />
      <path
        d="M282.42 133.668C282.42 126.988 287.06 122.948 292.7 122.948C298.34 122.948 302.98 126.988 302.98 133.668C302.98 140.348 298.34 144.468 292.7 144.468C287.06 144.468 282.42 140.348 282.42 133.668ZM296.54 134.788C296.54 130.788 295.02 127.748 292.34 127.748C290.3 127.748 288.86 129.388 288.86 132.548C288.86 136.508 290.34 139.628 293.02 139.628C295.06 139.628 296.54 137.988 296.54 134.788Z"
        fill="#502314"
      />
      <path
        d="M310.22 142.308L305.34 130.428C304.46 128.268 303.78 127.508 303.1 126.548C302.02 125.028 302.66 123.508 304.78 123.508H310.3C311.98 123.508 312.58 124.508 311.82 126.068C311.54 126.628 311.42 127.308 312.02 129.068L313.58 133.548C313.7 133.948 314.06 133.948 314.22 133.548L317.66 124.708C317.98 123.868 318.54 123.508 319.42 123.508H320.46C321.3 123.508 321.94 123.868 322.22 124.708L325.26 133.588C325.38 133.988 325.74 133.988 325.9 133.588L327.86 128.588C328.34 127.388 328.06 126.628 327.78 125.788C327.34 124.428 327.98 123.508 329.42 123.508H332.98C334.54 123.508 335.22 124.868 334.22 126.068C333.66 126.748 333.06 127.548 332.5 128.988L327.14 142.308C326.66 143.548 326.26 144.188 325.06 144.188H324.42C323.3 144.188 322.78 143.588 322.3 142.468L318.86 134.188C318.7 133.828 318.34 133.828 318.22 134.188L315.18 142.308C314.66 143.708 314.26 144.188 312.98 144.188H312.34C311.18 144.188 310.7 143.468 310.22 142.308Z"
        fill="#502314"
      />
      <path
        d="M336.02 141.668C336.34 140.548 336.54 139.628 336.54 137.908V128.428C336.54 127.348 336.26 126.508 336.02 125.948C335.62 124.988 336.18 123.828 337.26 123.628L340.38 123.028C342.18 122.668 342.94 123.388 342.94 124.748V125.268C342.94 125.748 343.3 126.068 343.74 125.468C344.82 123.988 347.02 122.948 349.22 122.948C353.22 122.948 355.58 125.748 355.58 130.508V137.908C355.58 139.628 355.78 140.548 356.1 141.668C356.5 143.068 355.86 143.908 354.42 143.908H350.34C348.9 143.908 348.26 143.068 348.66 141.668C348.98 140.548 349.18 139.628 349.18 137.908V131.508C349.18 129.348 347.94 127.868 346.06 127.868C344.18 127.868 342.94 129.348 342.94 131.508V137.908C342.94 139.628 343.14 140.548 343.46 141.668C343.86 143.068 343.22 143.908 341.78 143.908H337.7C336.26 143.908 335.62 143.068 336.02 141.668Z"
        fill="#502314"
      />
      <path
        d="M360.62 143.468C359.78 143.028 358.9 142.468 358.62 141.588C358.34 140.748 358.18 139.468 357.94 138.188C357.54 136.108 360.94 135.428 361.86 136.948C363.1 139.028 364.22 139.748 365.94 139.748C367.38 139.748 368.3 139.108 368.3 138.148C368.3 136.948 367.3 136.308 363.58 135.388C360.94 134.748 358.18 132.868 358.18 129.348C358.18 125.228 361.9 122.948 366.5 122.948C368.14 122.948 369.74 123.228 371.18 123.748C372.14 124.108 372.82 124.708 373.06 125.668C373.22 126.268 373.42 127.108 373.58 128.148C373.94 130.548 370.74 131.228 369.78 129.828C368.78 128.388 367.66 127.188 366.14 127.188C364.62 127.188 363.86 127.748 363.86 128.668C363.86 129.868 365.18 130.468 368.5 131.348C371.82 132.228 374.46 133.668 374.46 137.708C374.46 142.108 370.78 144.468 365.94 144.468C363.3 144.468 361.62 143.988 360.62 143.468Z"
        fill="#502314"
      />
      <path
        d="M376.98 140.668C376.98 138.588 378.74 136.868 380.98 136.868C383.22 136.868 384.98 138.588 384.98 140.668C384.98 142.748 383.22 144.468 380.98 144.468C378.74 144.468 376.98 142.748 376.98 140.668Z"
        fill="#502314"
      />
      <path
        d="M481.22 141.828C481.22 141.388 481.34 140.948 481.62 140.508C482.3 139.388 482.7 138.108 482.7 136.148V123.748C482.7 121.788 482.3 120.508 481.62 119.388C481.34 118.948 481.22 118.508 481.22 118.068C481.22 116.908 482.1 115.948 483.62 115.948H493.02C499.62 115.948 503.02 119.468 503.02 123.628C503.02 126.588 501.26 128.948 499.14 130.068C498.66 130.308 498.7 130.628 499.18 130.868C500.78 131.708 501.74 132.948 502.06 135.028C502.58 138.228 503.54 139.468 504.86 140.868C505.9 141.988 505.42 143.948 503.62 143.948H499.18C497.22 143.948 496.7 142.908 496.58 141.828C496.46 140.828 496.22 139.148 495.9 136.428C495.58 133.788 494.7 132.908 491.82 132.908H490.54C489.82 132.908 489.42 133.308 489.42 134.028V136.148C489.42 138.108 489.78 139.388 490.5 140.508C490.78 140.948 490.9 141.388 490.9 141.828C490.9 142.988 490.02 143.948 488.5 143.948H483.62C482.1 143.948 481.22 142.988 481.22 141.828ZM492.34 128.108C494.98 128.108 496.54 126.828 496.54 124.428C496.54 122.028 494.98 120.748 492.34 120.748H490.54C489.82 120.748 489.42 121.148 489.42 121.868V126.988C489.42 127.708 489.82 128.108 490.54 128.108H492.34Z"
        fill="#502314"
      />
      <path
        d="M505.26 133.708C505.26 127.708 509.38 122.988 515.7 122.988C522.02 122.988 524.98 127.428 524.98 132.508C524.98 134.228 524.26 134.828 522.98 134.828H512.5C512.1 134.828 511.9 135.068 511.98 135.508C512.42 137.588 514.26 139.348 516.58 139.348C518.42 139.348 519.9 138.628 521.18 137.628C523.02 136.188 524.82 137.748 523.94 139.468C522.42 142.388 519.82 144.508 515.54 144.508C509.38 144.508 505.26 140.388 505.26 133.708ZM517.94 130.988C518.46 130.988 518.74 130.668 518.66 130.108C518.5 128.668 517.3 127.748 515.58 127.748C514.02 127.748 512.78 128.668 512.14 130.308C511.98 130.708 512.18 130.988 512.62 130.988H517.94Z"
        fill="#502314"
      />
      <path
        d="M526.74 133.708C526.74 126.628 530.26 122.988 535.26 122.988C537.1 122.988 538.7 123.588 539.94 124.748C540.38 125.148 540.7 124.908 540.7 124.468V120.388C540.7 119.308 540.42 118.468 540.18 117.908C539.78 116.948 540.1 115.828 541.42 115.588L544.54 114.988C546.34 114.628 547.1 115.348 547.1 116.708V139.028C547.1 140.108 547.38 140.948 547.62 141.508C548.02 142.468 547.46 143.628 546.38 143.828L543.26 144.428C541.46 144.788 540.7 144.068 540.7 142.708V142.388C540.7 141.828 540.26 141.668 539.86 142.148C538.7 143.548 537.02 144.508 535.06 144.508C530.18 144.508 526.74 140.388 526.74 133.708ZM540.54 134.388C540.54 130.308 539.3 127.628 536.74 127.628C534.86 127.628 533.34 129.588 533.34 133.268C533.34 136.948 534.7 139.908 537.14 139.908C539.02 139.908 540.54 137.868 540.54 134.388Z"
        fill="#502314"
      />
      <path
        d="M549.58 133.708C549.58 127.708 553.7 122.988 560.02 122.988C566.34 122.988 569.3 127.428 569.3 132.508C569.3 134.228 568.58 134.828 567.3 134.828H556.82C556.42 134.828 556.22 135.068 556.3 135.508C556.74 137.588 558.58 139.348 560.9 139.348C562.74 139.348 564.22 138.628 565.5 137.628C567.34 136.188 569.14 137.748 568.26 139.468C566.74 142.388 564.14 144.508 559.86 144.508C553.7 144.508 549.58 140.388 549.58 133.708ZM562.26 130.988C562.78 130.988 563.06 130.668 562.98 130.108C562.82 128.668 561.62 127.748 559.9 127.748C558.34 127.748 557.1 128.668 556.46 130.308C556.3 130.708 556.5 130.988 556.94 130.988H562.26Z"
        fill="#502314"
      />
      <path
        d="M571.06 133.708C571.06 127.708 575.18 122.988 581.5 122.988C587.82 122.988 590.78 127.428 590.78 132.508C590.78 134.228 590.06 134.828 588.78 134.828H578.3C577.9 134.828 577.7 135.068 577.78 135.508C578.22 137.588 580.06 139.348 582.38 139.348C584.22 139.348 585.7 138.628 586.98 137.628C588.82 136.188 590.62 137.748 589.74 139.468C588.22 142.388 585.62 144.508 581.34 144.508C575.18 144.508 571.06 140.388 571.06 133.708ZM583.74 130.988C584.26 130.988 584.54 130.668 584.46 130.108C584.3 128.668 583.1 127.748 581.38 127.748C579.82 127.748 578.58 128.668 577.94 130.308C577.78 130.708 577.98 130.988 578.42 130.988H583.74Z"
        fill="#502314"
      />
      <path
        d="M592.58 141.708C592.9 140.588 593.1 139.668 593.1 137.948V128.468C593.1 127.388 592.82 126.548 592.58 125.988C592.18 125.028 592.74 123.868 593.82 123.668L596.94 123.068C598.74 122.708 599.5 123.428 599.5 124.788V125.308C599.5 125.788 599.86 126.108 600.3 125.508C601.34 124.108 602.86 122.988 605.06 122.988C607.1 122.988 608.74 123.868 609.66 125.508C609.82 125.788 610.14 125.868 610.42 125.508C611.58 124.028 613.42 122.988 616.26 122.988C619.78 122.988 621.9 125.748 621.9 129.748V137.948C621.9 139.668 622.1 140.588 622.42 141.708C622.82 143.108 622.18 143.948 620.74 143.948H616.66C615.22 143.948 614.58 143.108 614.98 141.708C615.3 140.588 615.5 139.668 615.5 137.948V130.948C615.5 129.108 614.66 127.908 613.1 127.908C611.54 127.908 610.7 129.148 610.7 130.948V137.948C610.7 139.668 610.9 140.588 611.22 141.708C611.62 143.108 610.98 143.948 609.54 143.948H605.46C604.02 143.948 603.38 143.108 603.78 141.708C604.1 140.588 604.3 139.668 604.3 137.948V130.948C604.3 129.108 603.46 127.908 601.9 127.908C600.34 127.908 599.5 129.108 599.5 130.948V137.948C599.5 139.668 599.7 140.588 600.02 141.708C600.42 143.108 599.78 143.948 598.34 143.948H594.26C592.82 143.948 592.18 143.108 592.58 141.708Z"
        fill="#502314"
      />
      <path
        d="M631.42 141.708C631.74 140.588 631.94 139.668 631.94 137.948V128.468C631.94 127.388 631.66 126.548 631.42 125.988C631.02 125.028 631.58 123.868 632.66 123.668L635.78 123.068C637.58 122.708 638.34 123.428 638.34 124.788V125.308C638.34 125.788 638.66 126.108 639.14 125.508C640.46 123.868 641.54 122.988 643.34 122.988C647.9 122.988 647.58 131.268 643.5 131.268C641.3 131.268 641.18 128.748 639.98 128.748C638.94 128.748 638.34 130.228 638.34 132.388V137.948C638.34 139.668 638.54 140.588 638.86 141.708C639.26 143.108 638.62 143.948 637.18 143.948H633.1C631.66 143.948 631.02 143.108 631.42 141.708Z"
        fill="#502314"
      />
      <path
        d="M647.54 133.708C647.54 127.708 651.66 122.988 657.98 122.988C664.3 122.988 667.26 127.428 667.26 132.508C667.26 134.228 666.54 134.828 665.26 134.828H654.78C654.38 134.828 654.18 135.068 654.26 135.508C654.7 137.588 656.54 139.348 658.86 139.348C660.7 139.348 662.18 138.628 663.46 137.628C665.3 136.188 667.1 137.748 666.22 139.468C664.7 142.388 662.1 144.508 657.82 144.508C651.66 144.508 647.54 140.388 647.54 133.708ZM660.22 130.988C660.74 130.988 661.02 130.668 660.94 130.108C660.78 128.668 659.58 127.748 657.86 127.748C656.3 127.748 655.06 128.668 654.42 130.308C654.26 130.708 654.46 130.988 654.9 130.988H660.22Z"
        fill="#502314"
      />
      <path
        d="M675.06 142.348L670.18 130.468C669.3 128.308 668.62 127.548 667.94 126.588C666.86 125.068 667.5 123.548 669.62 123.548H675.14C676.82 123.548 677.42 124.548 676.66 126.108C676.38 126.668 676.26 127.348 676.86 129.108L678.42 133.588C678.54 133.988 678.9 133.988 679.06 133.588L682.5 124.748C682.82 123.908 683.38 123.548 684.26 123.548H685.3C686.14 123.548 686.78 123.908 687.06 124.748L690.1 133.628C690.22 134.028 690.58 134.028 690.74 133.628L692.7 128.628C693.18 127.428 692.9 126.668 692.62 125.828C692.18 124.468 692.82 123.548 694.26 123.548H697.82C699.38 123.548 700.06 124.908 699.06 126.108C698.5 126.788 697.9 127.588 697.34 129.028L691.98 142.348C691.5 143.588 691.1 144.228 689.9 144.228H689.26C688.14 144.228 687.62 143.628 687.14 142.508L683.7 134.228C683.54 133.868 683.18 133.868 683.06 134.228L680.02 142.348C679.5 143.748 679.1 144.228 677.82 144.228H677.18C676.02 144.228 675.54 143.508 675.06 142.348Z"
        fill="#502314"
      />
      <path
        d="M699.58 138.428C699.58 133.748 703.7 131.268 710.02 132.348C710.5 132.428 710.74 132.188 710.74 131.628V129.948C710.74 127.628 709.9 127.068 708.54 127.068C707.54 127.068 706.74 127.948 706.34 129.148C705.82 130.748 704.98 131.548 703.22 131.548C701.26 131.548 700.1 130.148 700.1 128.268C700.1 125.308 703.58 122.988 708.46 122.988C714.46 122.988 717.14 125.828 717.14 130.348V135.708C717.14 137.868 717.46 139.028 718.46 140.508C719.18 141.548 718.7 142.468 717.62 142.908L715.3 143.868C714.5 144.188 713.9 144.428 713.3 144.428C712.7 144.428 712.22 143.748 711.78 142.508C711.58 141.908 711.18 141.868 710.78 142.348C709.74 143.588 708.22 144.508 706.06 144.508C702.06 144.508 699.58 141.908 699.58 138.428ZM710.74 136.908C710.74 136.108 709.74 135.508 708.66 135.508C706.94 135.508 705.98 136.428 705.98 137.828C705.98 138.868 706.66 139.868 708.02 139.868C709.62 139.868 710.74 138.508 710.74 136.908Z"
        fill="#502314"
      />
      <path
        d="M720.38 141.708C720.7 140.588 720.9 139.668 720.9 137.948V128.468C720.9 127.388 720.62 126.548 720.38 125.988C719.98 125.028 720.54 123.868 721.62 123.668L724.74 123.068C726.54 122.708 727.3 123.428 727.3 124.788V125.308C727.3 125.788 727.62 126.108 728.1 125.508C729.42 123.868 730.5 122.988 732.3 122.988C736.86 122.988 736.54 131.268 732.46 131.268C730.26 131.268 730.14 128.748 728.94 128.748C727.9 128.748 727.3 130.228 727.3 132.388V137.948C727.3 139.668 727.5 140.588 727.82 141.708C728.22 143.108 727.58 143.948 726.14 143.948H722.06C720.62 143.948 719.98 143.108 720.38 141.708Z"
        fill="#502314"
      />
      <path
        d="M736.34 133.708C736.34 126.628 739.86 122.988 744.86 122.988C746.7 122.988 748.3 123.588 749.54 124.748C749.98 125.148 750.3 124.908 750.3 124.468V120.388C750.3 119.308 750.02 118.468 749.78 117.908C749.38 116.948 749.7 115.828 751.02 115.588L754.14 114.988C755.94 114.628 756.7 115.348 756.7 116.708V139.028C756.7 140.108 756.98 140.948 757.22 141.508C757.62 142.468 757.06 143.628 755.98 143.828L752.86 144.428C751.06 144.788 750.3 144.068 750.3 142.708V142.388C750.3 141.828 749.86 141.668 749.46 142.148C748.3 143.548 746.62 144.508 744.66 144.508C739.78 144.508 736.34 140.388 736.34 133.708ZM750.14 134.388C750.14 130.308 748.9 127.628 746.34 127.628C744.46 127.628 742.94 129.588 742.94 133.268C742.94 136.948 744.3 139.908 746.74 139.908C748.62 139.908 750.14 137.868 750.14 134.388Z"
        fill="#502314"
      />
      <path
        d="M761.74 143.508C760.9 143.068 760.02 142.508 759.74 141.628C759.46 140.788 759.3 139.508 759.06 138.228C758.66 136.148 762.06 135.468 762.98 136.988C764.22 139.068 765.34 139.788 767.06 139.788C768.5 139.788 769.42 139.148 769.42 138.188C769.42 136.988 768.42 136.348 764.7 135.428C762.06 134.788 759.3 132.908 759.3 129.388C759.3 125.268 763.02 122.988 767.62 122.988C769.26 122.988 770.86 123.268 772.3 123.788C773.26 124.148 773.94 124.748 774.18 125.708C774.34 126.308 774.54 127.148 774.7 128.188C775.06 130.588 771.86 131.268 770.9 129.868C769.9 128.428 768.78 127.228 767.26 127.228C765.74 127.228 764.98 127.788 764.98 128.708C764.98 129.908 766.3 130.508 769.62 131.388C772.94 132.268 775.58 133.708 775.58 137.748C775.58 142.148 771.9 144.508 767.06 144.508C764.42 144.508 762.74 144.028 761.74 143.508Z"
        fill="#502314"
      />
      <path
        d="M778.1 140.708C778.1 138.628 779.86 136.908 782.1 136.908C784.34 136.908 786.1 138.628 786.1 140.708C786.1 142.788 784.34 144.508 782.1 144.508C779.86 144.508 778.1 142.788 778.1 140.708Z"
        fill="#502314"
      />
    </g>
    <defs>
      <clipPath id="clip0_182_27585">
        <rect
          width="798.93"
          height="143.97"
          fill="white"
          transform="translate(0.0999756 0.547852)"
        />
      </clipPath>
    </defs>
  </svg>
);
